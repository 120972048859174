import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

import { host, siteUrl } from 'site/constants';


/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */

export default function resolveConfig() {
  return {
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        dev: '//s1.blog.okko.tv/api/v2',
        production: '//blog.okko.tv/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/okkomedia',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    counterIds: {
      yandexMetrika: 67996255,
    },

    isUnityProject: false,

    schemaOrg: {
      organizationName: host,
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 230,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s - OKKOLOKINO',
      defaultTitle: 'Okkoлокино — медиа о кино: подборки, статьи, новости и рецензии',
      meta: [
        {
          name: 'description',
          content: 'С Okkoлокино вас ждут свежие новости и необычные факты из мира кино. Мы поможем быстро найти фильм или сериал под любое настроение. Посмеяться, поплакать, удивиться, влюбиться, отдохнуть или даже ужаснуться — всё к нам',
        },
      ],
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
    },

    specialLinks: [
      '/exports/rss',
      '/about-us', // чтобы работала хеш навигация
    ],

    socials: [{
      link: 'https://vk.com/okkomovies',
      name: 'vkontakte',
    },
    {
      link: 'https://ok.ru/okkomovies',
      name: 'odnoklassniki',
    },
    {
      link: 'https://t.me/+tse-rwjr9R4wYTdi',
      name: 'telegram',
    },
    {
      link: 'https://www.youtube.com/@okkomovies',
      name: 'youtube',
    },
    {
      link: 'https://dzen.ru/okkomovies',
      name: 'Yandex_Dzen',
    },
    {
      link: 'https://okkomovies.livejournal.com/',
      name: 'LivejournalWhite',
    },
    {
      link: 'https://www.tiktok.com/@okkomovies?lang=ru-RU',
      name: 'tiktok',
    }],
  };
}
