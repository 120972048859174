export const host = 'blog.okko.tv';
export const siteUrl = 'https://' + host;

export const basicSlug = '[a-z0-9\-_]+';
export const topicSlug = '[a-z0-9\-_.]+\/[a-z0-9\-_.]+';

export const OKKO_TV_URL = 'https://okko.tv/';

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 20;

export const datePattern = 'dd MMMM yyyy HH:mm';

export const SELECTIONS_SLUG = 'selections';
export const TABLE_OF_CONTENTS_TITLE = 'Содержание';
