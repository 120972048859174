import PropTypes from 'prop-types';
import { matchPath } from 'react-router';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import { withRouter } from 'core/libs/router';
import { topicAmp as topicAmpPattern } from 'core/components/BaseRoutes/routes-patterns';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Menu from 'core/components/ScooterMenu';
import Link from 'core/components/Link';
import Social from 'core/components/Social';
import PopcornButton from 'site/components/PopcornButton';
import Scroller from 'core/components/Scroller';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import Logo from 'site/icons/Logo';


function Scooter({ theme, isMobile, location }) {
  const {
    controls: {
      scooter: scooterAtoms,
    },
  } = theme;

  const thisYear = new Date().getFullYear();

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .footer
          display flex
          flex-direction column
          font ${scooterAtoms.font}
          padding ${scooterAtoms.padding}
          padding-left 0
          padding-right 0

        .innerWrapper
          display flex
          gap 20px
          justify-content space-between
          align-items flex-start
          flex-direction row
          overflow hidden
          margin-top 20px
          :global(.mobile) &
            flex-direction column
            align-items center
            text-align center

        .nav
          align-items center
        .popcornButton
          :global(.desktop) &
            flex 0 0 237px

          :global(.mobile) &
            width 100%

        .copyright
          flex 1

        .mistakeWarning
          text-align right
          margin-left 20px


        .year
          :global(.desktop) &
            margin-left 20px
          :global(.mobile) &
            margin-top 10px

        .menu
          margin 0 auto
          padding-left 0

        .menuList
          min-width initial

        .copyright,
        .year
          opacity .5


        .hotkeys
          color ${theme.colors.accent}

        .legalWrapper
          display flex
          gap 30px
          :global(.mobile) &
            gap 10px
            flex-direction column
        .link
        .link:visited
          color ${theme.colors.primary}
          text-decoration none
          &:hover
            text-decoration none
            color ${theme.colors.linkColor}
      `}</style>
    </scope>
  );

  const MenuWrapper = isMobile ? Scroller : 'div';
  const isAmp = !!matchPath(location.pathname, topicAmpPattern);

  return (
    <WideWrapper>
      <div className={scoped.wrapClassNames('footer')}>
        <div className={scoped.wrapClassNames('innerWrapper', 'nav')}>
          <div className={scoped.wrapClassNames('logo')}>
            <Link to='/' title='На главную'>
              <Logo
                width={scooterAtoms.logo.width}
                height={scooterAtoms.logo.height}
              />
            </Link>
          </div>
          <MenuWrapper
            className={scoped.wrapClassNames('menu')}
            {...isMobile && { listClassName: scoped.wrapClassNames('menuList') }}
          >
            <Menu theme={theme} isAmp={isAmp} />
          </MenuWrapper>
          <div className={scoped.wrapClassNames('popcornButton')}>
            <PopcornButton />
          </div>
        </div>
        <div className={scoped.wrapClassNames('innerWrapper')}>
          <div className={scoped.wrapClassNames('legalWrapper')}>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/about-us'
              >
                О проекте
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/authors'
              >
                Авторы
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/about-us#contacts'
              >
                Контакты
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/legal'
              >
                Правовая информация
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/legal/terms-of-use'
              >
                Условия использования
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link')}
                to='/legal/privacy'
              >
                Политика конфиденциальности
              </Link>
            </span>
          </div>
        </div>
        <div className={scoped.wrapClassNames('innerWrapper')}>
          <div className={scoped.wrapClassNames('copyright')}>
            {thisYear} Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.
            Использование материалов в рамках подпункта 3 пункта 1 статьи 1274 ГК РФ без разрешения ООО «ОККО» запрещено.
            Использование материалов ООО «ОККО» возможно на следующих условиях:
            Безвозмездно в порядке цитирования: объем использования – не более 30% оригинального материала при условии гиперссылки на сайт (оригинальный материал). В иных случаях – с письменного согласия ООО «ОККО».
          </div>
          {!isMobile && (
            <div className={scoped.wrapClassNames('mistakeWarning')}>
              Нашли опечатку? <br /> Выделите текст и нажмите <span className={scoped.wrapClassNames('hotkeys')}>Ctrl+Enter</span>
            </div>

          )}
        </div>
        <div className={scoped.wrapClassNames('innerWrapper')}>
          <Social />
          <div className={scoped.wrapClassNames('year')}>
            &copy;&nbsp;{thisYear}&nbsp;&nbsp;г.
          </div>
        </div>
        <scoped.styles />
      </div>
    </WideWrapper>
  );
}

Scooter.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withTheme(withBreakpoint(withRouter(Scooter)));
