import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import { initialAppStatusProvider } from 'core/components/BaseRoutes';
import isError4xx from 'core/utils/statuscode-helper';

import ContentBackground from 'core/components/ContentBackground';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Desktop, Mobile } from 'core/components/breakpoint';

import EmptyWrapper from 'core/components/EmptyWrapper';

import NoiseWrapper from 'site/components/NoiseWrapper';
import Shapka from 'site/components/Shapka';
import Scooter from 'site/components/Scooter';
import { Fullscreen } from 'site/components/Ads';


function SiteLayout({ children, location, initialAppStatus, staticContext }) {
  const isMainPage = location.pathname === '/';

  const { appStatus } = staticContext || location.state || {};
  const isError = isError4xx(initialAppStatus || appStatus);
  const OuterWrapper = isError ? NoiseWrapper : EmptyWrapper;
  const ContentBackgroundWrapper = isError ? EmptyWrapper : ContentBackground;
  const shlyapaElement = (
    <Header>
      <Shapka isErrorPage={isError} />
    </Header>
  );

  return (
    <StickyContainer>
      <OuterWrapper>
        <ContentBackgroundWrapper>
          <Layout>
            {!isMainPage && (
              <Desktop>
                {shlyapaElement}
              </Desktop>
            )}
            <Mobile>{shlyapaElement}</Mobile>
            <Content>
              {children}
            </Content>
            {!isError && (
              <Footer>
                <Scooter />
              </Footer>
            )}
          </Layout>
        </ContentBackgroundWrapper>
        <Fullscreen />
      </OuterWrapper>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  location: PropTypes.object,
  staticContext: PropTypes.object,
  initialAppStatus: PropTypes.any,
};

export default withRouter(initialAppStatusProvider(SiteLayout));
