import MontserratSemiBold from './Montserrat/montserrat-semibold.woff';
import MontserratSemiBold2 from './Montserrat/montserrat-semibold.woff2';

import SuisseIntl from './SuisseIntl/suisseintl-regular.woff';
import SuisseIntl2 from './SuisseIntl/suisseintl-regular.woff2';
import SuisseIntlBook from './SuisseIntl/suisseintl-book.woff';
import SuisseIntlBook2 from './SuisseIntl/suisseintl-book.woff2';
import SuisseIntlMedium from './SuisseIntl/suisseintl-medium.woff';
import SuisseIntlMedium2 from './SuisseIntl/suisseintl-medium.woff2';
import SuisseIntlBold from './SuisseIntl/suisseintl-bold.woff';
import SuisseIntlBold2 from './SuisseIntl/suisseintl-bold.woff2';

const fonts = {
  display: 'SuisseIntl, sans-serif',
  text: 'SuisseIntl, sans-serif',
  nuance: 'Montserrat, sans-serif',
  faces: [
    {

      fontFamily: 'Montserrat',
      fontWeight: 600,
      src: `local('Montserrat-SemiBold'), url(${MontserratSemiBold2}) format('woff2'), url(${MontserratSemiBold}) format('woff')`,
    }, {

      fontFamily: 'SuisseIntl',
      src: `local('SuisseIntl'), url(${SuisseIntl2}) format('woff2'), url(${SuisseIntl}) format('woff')`,
    }, {
      fontFamily: 'SuisseIntl',
      fontWeight: 500,
      src: `local('SuisseIntl-Medium'), url(${SuisseIntlMedium2}) format('woff2'), url(${SuisseIntlMedium}) format('woff')`,
    }, {
      fontFamily: 'SuisseIntl',
      fontWeight: 700,
      src: `local('SuisseIntl-Bold'), url(${SuisseIntlBold2}) format('woff2'), url(${SuisseIntlBold}) format('woff')`,
    },
    {
      fontFamily: 'SuisseIntlBook',
      fontWeight: 700,
      src: `local('SuisseIntl-Book'), url(${SuisseIntlBook2}) format('woff2'), url(${SuisseIntlBook}) format('woff')`,
    },
  ],
};

export default fonts;
