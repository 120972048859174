import color from 'core/libs/color';

import palette from './palette';

const colors = {
  primary: palette.white,
  content: palette.electroPurple800,
  active2: palette.electroPurple500,
  purple: palette.electroPurple100,
  active1: palette.white,
  input: palette.white,
  gradient: `conic-gradient(
    from 0 at 50% 50%,
    ${palette.white} -20deg,
    ${palette.electroPurple500} 65deg,
    ${palette.electroPurple500} 155deg,
    ${palette.violet800} 242deg,
    ${palette.white} 340deg,
    ${palette.electroPurple500} 425deg
  )`,
  gradient2: 'linear-gradient(-45deg, #000000, #30017B 30%, #000000)',
  gradient3: 'radial-gradient(43% 110% at 3.5% 0%, #6C00FF 0%, #2D086A 100%)',
  gradient4: 'linear-gradient(0deg, rgba(20, 0, 53, 0.9) 40%, rgba(20, 0, 53, 0.6) 80%, rgba(20, 0, 53, 0))',
  placeholder: color(palette.white).alpha(0.5).string(),
  hoverHeadlineColor: palette.electroPurple200,
  rubricColor: palette.electroPurple200,
  timeTextColor: palette.gray500,
  gray1: palette.gray600,
  gray2: palette.gray600,
  grey3: palette.gray900,
  primaryButtonHoverColor: palette.electroPurple100,
  caption: palette.white,
  credits: palette.gray500,
};

const gradientButton = {
  type: 'bordered',
  background: colors.gradient,
};

const popcornButton = {
  popcornOffset: 0,
};

const skeleton = {
  background: color(colors.content).alpha(0.3).string(),
};

/**
 * TODO: Дизайнер обещал дать цвета
 */
const shapka = {
  link: {
    idle: {
      color: colors.primary,
      decoration: 'none',
    },

    hover: {
      color: 'aqua',
      decoration: 'none',
    },

    active: {
      color: 'greenyellow',
      decoration: 'none',
    },

    current: {
      color: colors.primary,
      decoration: 'underline',
    },
  },
  primary: palette.white, // Основной цвет, текст
  layout: palette.violet900, // цвет фона body
  content: palette.violet900, // цвет фона контентной области
  divider: palette.gray300,

  hoverHeadlineColor: palette.electroPurple200,  // цвет хедлайна при наведении на карточку
  rubricColor: palette.electroPurple200,
  timeTextColor: palette.gray500,
  tagsTextColor: palette.gray500,
  linkColor: palette.electroPurple200,  // цвет ссылок

  chips: palette.gray900,
};

const button = {
  primary: {
    idle: {
      color: colors.primary,
      background: colors.gradient3,
      border: 'none',
    },

    hover: {
      color: colors.primaryButtonHoverColor,
      background: colors.gradient3,
      border: 'none',
    },

    active: {
      color: colors.primaryButtonHoverColor,
      background: colors.gradient3,
      border: 'none',
    },

    disabled: {
      color: colors.primary,
      background: colors.gradient3,
      border: 'none',
    },
  },

  tab: {
    idle: {
      color: colors.primary,
      background: colors.grey3,
      border: 'none',
    },

    hover: {
      color: colors.primary,
      background: colors.active2,
      border: 'none',
    },

    active: {
      color: colors.primary,
      background: colors.active2,
      border: 'none',
    },

    disabled: {
      color: color(colors.primary).alpha(0.9).string(),
      background: color(colors.grey2).alpha(0.7).string(),
      border: 'none',
    },
  },
};

const promoButton = {
  type: 'white',
};

export default {
  colors,
  controls: {
    skeleton,
    button,
    shapka,
    gradientButton,
    popcornButton,
    promoButton,
  },
  pages: {
    notFound: {
      title: {
        color: colors.primary,
      },
      subtitle: {
        color: colors.primary,
      },
    },
  },
};
