
import styles from '../index.styl';

function Privacy() {
  return (<>
    <p><i>Редакция от 22 мая 2023 года</i></p>
    <p><i>Политика конфиденциальности определяет политику Оператора в отношении обработки Данных Пользователей при использовании ими Ресурса, а также содержит сведения о реализуемых требованиях к защите персональных данных Пользователей.</i></p>

    <ol>
      <li>
        <h3 className={styles.subtitle}>Термины</h3>
        <ol>
          <li><b>Оператор</b> – юридическое лицо, предоставляющее Пользователям Ресурс. Наименование Оператора размещено на Ресурсе.</li>
          <li><b>Ресурс</b> – сайт, проект, сервис и/или иной продукт в сети Интернет, предоставляемый Оператором.</li>
          <li><b>Пользователь</b> – физическое лицо, использующее Ресурс и обладающее необходимой дееспособностью для заключения и исполнения Условий использования Ресурса.</li>
          <li><b>Данные</b> – персональные данные Пользователя, а также иная пользовательская информация.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Действие политики</h3>
        <ol>
          <li>Осуществляя доступ к Ресурсу, Пользователь соглашается с условиями обработки Данных Оператором, описанными в настоящей Политике. В случае несогласия Пользователя с такими условиями использование Ресурса должно быть прекращено.</li>
          <li>Политика может быть изменена Оператором. Измененная Политика размещается на Ресурсе. Используя Ресурс после изменения Политики, Пользователь соглашается с измененными условиями обработки Данных.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Цель и условия обработки</h3>
        <ol>
          <li>Оператор обрабатывает Данные с целью обеспечения доступа к интернет-ресурсам, включая:
            <ul>
              <li>предоставление определенного функционала Ресурсов (в том числе персонализация контента и иных материалов);</li>
              <li>получение Пользователем сообщений;</li>
              <li>продвижение Ресурсов, продуктов и/или услуг Оператора и его партнеров;</li>
              <li>прием обращений Пользователей и представление ответов на указанные обращения;</li>
              <li>проведение статистических и иных исследований использования Ресурсов;</li>
              <li>выполнение возложенных законодательством на Оператора обязанностей.</li>
            </ul>
          </li>
          <li>Для цели, указанной в п. 3.1. Политики, применимы следующие условия обработки:
            <ol>
              <li>Перечень обрабатываемых Данных: адрес электронной почты; сведения, собираемые посредством метрических программ; сведения о пользовательском устройстве; cookies, идентификатор пользователя, данные об использовании ресурса; сетевой адрес.</li>
              <li>Оператором не обрабатываются биометрические и специальные категории персональных данных Пользователей. Обрабатываемые Оператором персональные данные относятся к иным категориям персональных данных.</li>
              <li>Способы обработки Данных: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</li>
              <li>Срок обработки и хранения Данных: до достижения цели обработки, до истечения срока действия согласия или до отзыва согласия (если отсутствуют иные основания для обработки таких Данных), в зависимости от того, какое событие наступило раньше.</li>
            </ol>
          </li>
          <li>Оператор обязуется обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных Пользователей с использованием баз данных, находящихся на территории РФ.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Основания обработки</h3>
        <ol>
          <li>Правовыми основаниями обработки Данных Оператором являются в том числе:
            <ul>
              <li>согласие Пользователя;</li>
              <li>исполнение, а также заключение Условий использования Ресурса или иных соглашений с Пользователем;</li>
              <li>осуществление прав и законных интересов Оператора или третьих лиц, если при этом не нарушаются права и свободы Пользователя;</li>
              <li>необходимость обработки для осуществления профессиональной деятельности журналиста и/или законной деятельности средства массовой информации либо научной, литературной или иной творческой деятельности при условии, что при этом не нарушаются права и законные интересы Пользователя;</li>
              <li>обработка в статистических или иных исследовательских целях, при условии обязательного обезличивания Данных;</li>
              <li>необходимость обработки для достижения целей, предусмотренных законом, а также выполнение обязанностей, возложенных на Оператора законодательством. </li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Права пользователя</h3>
        <ol>
          <li>Пользователь вправе:
            <ul>
              <li>получать информацию, касающуюся обработки своих Данных;</li>
              <li>требовать от Оператора уточнения его Данных, их блокирования или уничтожения в случае, если они неполные, устаревшие, неточные, незаконно полученные или не являются необходимыми для заявленной цели обработки;</li>
              <li>отозвать согласие на обработку своих Данных, направив Оператору заявление в письменной форме;</li>
              <li>принимать предусмотренные законодательством РФ меры по защите своих прав.</li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Условия использования cookies</h3>
        <ol>
          <li>В рамках цели, указанной в п. 3.1 Политики, Оператор использует сookies (небольшие фрагменты данных о прошлых посещениях). Соглашаясь с условиями обработки Данных, изложенными в настоящей Политике, Пользователь в том числе соглашается на обработку cookies в вышеуказанной цели. Пользователь может просмотреть срок действия cookies, а также запретить их сохранение в настройках своего браузера.</li>
          <li>Сookies и иные сведения могут обрабатываться в том числе с использованием метрических программ, в частности, Рамблер/топ-100, Яндекс.Метрика, Google Analytics, Рейтинг@mail.ru, LiveInternet, Mediascope.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Порядок уничтожения</h3>
        <ol>
          <li>В отношении Данных Пользователя, обрабатываемых Оператором в указанной в п. 3.1 Политики цели, действует единый порядок их уничтожения в соответствии с законодательством РФ и локальными нормативными актами Оператора.</li>
          <li>Данные подлежат уничтожению Оператором, в частности, если достигнута цель обработки или Пользователем отозвано согласие на их обработку (и отсутствуют иные основания для обработки таких Данных), либо выявлен факт их неправомерной обработки.</li>
          <li>В случае достижения цели обработки Данных Оператор прекращает их обработку и уничтожает их в срок, не превышающий 30 дней с даты достижения цели обработки. В случае отзыва Пользователем согласия на обработку его Данных, Оператор прекращает их обработку и в случае, если сохранение Данных более не требуется для цели обработки, уничтожает их в срок, не превышающий 30 дней с даты поступления указанного отзыва.</li>
          <li>В случае выявления неправомерной обработки персональных данных, осуществляемой Оператором, Оператор в срок, не превышающий 3 рабочих дней с даты этого выявления, прекращает такую обработку. В случае, если обеспечить правомерность такой обработки невозможно, Оператор в срок, не превышающий 10 рабочих дней с даты выявления неправомерной обработки, уничтожает такие Данные. При этом Оператор уведомляет Пользователя об устранении допущенных нарушений или об уничтожении Данных.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Сведения о реализуемых требованиях к защите</h3>
        <ol>
          <li>Оператор обязуется обеспечивать конфиденциальность персональных данных Пользователей.</li>
          <li>
            <p>Оператор обеспечивает безопасность персональных данных Пользователей посредством реализации правовых, организационных и технических мер.</p>
            <ol>
              <li>
                <p>Правовые меры, принимаемые Оператором, включают:</p>
                <ul>
                  <li>издание Оператором документов, определяющих политику оператора в отношении обработки персональных данных, локальных актов по вопросам обработки персональных данных, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений.</li>
                </ul>
              </li>
              <li>
                <p>Организационные меры, принимаемые Оператором, включают:</p>
                <ul>
                  <li>обеспечение неограниченного доступа к Политике, к сведениям о реализуемых требованиях к защите персональных данных;</li>
                  <li>осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных Федеральному закону от 27.07.2006 N 152-ФЗ «О персональных данных» (Закон) и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, политике Оператора в отношении обработки персональных данных, локальным актам Оператора;</li>
                  <li>оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Закона, соотношение указанного вреда и принимаемых Оператором мер, направленных на обеспечение выполнения обязанностей, предусмотренных Законом;</li>
                  <li>ознакомление работников Оператора, непосредственно осуществляющих обработку персональных данных, с положениями законодательства РФ о персональных данных, в том числе требованиями к защите персональных данных, документами, определяющими политику Оператора в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных, и (или) обучение указанных работников;</li>
                  <li>назначение лица, ответственного за организацию обработки персональных данных;</li>
                  <li>назначение лица, ответственного за обеспечение безопасности персональных данных в информационных системах персональных данных (ИСПДн);</li>
                  <li>утверждение руководителем Оператора документа, определяющего перечень лиц, доступ которых к персональным данным, обрабатываемым в ИСПДн, необходим для выполнения ими служебных (трудовых) обязанностей;</li>
                  <li>обеспечение сохранности носителей персональных данных;</li>
                  <li>учет машинных носителей персональных данных;</li>
                  <li>определение угроз безопасности персональных данных при их обработке в ИСПДн;</li>
                  <li>организация контролируемой зоны, в пределах которой постоянно размещаются стационарные технические средства, обрабатывающие информацию, и средства защиты информации, а также средства обеспечения функционирования;</li>
                  <li>организация режима обеспечения безопасности помещений, в которых размещена ИСПДн, препятствующего возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения.</li>
                </ul>
              </li>
              <li>
                <p>Технические меры, принимаемые Оператором, включают:</p>
                <ul>
                  <li>использование средств защиты информации, прошедших процедуру оценки соответствия требованиям законодательства РФ в области обеспечения безопасности информации, в случае, когда применение таких средств необходимо для нейтрализации актуальных угроз;</li>
                  <li>оценку эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию ИСПДн; </li>
                  <li>установление правил доступа к персональным данным, обрабатываемым в ИСПДн, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в ИСПДн;</li>
                  <li>обнаружение фактов несанкционированного доступа к персональным данным и принятием мер, в том числе мер по обнаружению, предупреждению и ликвидации последствий компьютерных атак на ИСПДн и по реагированию на компьютерные инциденты в них;</li>
                  <li>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
                  <li>контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности ИСПДн.</li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Иное</h3>
        <ol>
          <li>Пользователь несет ответственность за правомерность предоставления, актуальность и достоверность данных Пользователя и иных лиц, которые Пользователь предоставляет Оператору.</li>
          <li>Оператор рассматривает обращения, связанные с Политикой, включая запросы Пользователей относительно использования их Данных по адресу: 197374, г. Санкт-Петербург, ул. Савушкина, д. 126 лит. Б, помещ. 71-Н.</li>
        </ol>
      </li>
    </ol>
  </>
  );
}

export default Privacy;
