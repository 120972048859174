import { Fragment } from 'react';
import cx from 'classnames';

import { Desktop, Mobile } from 'core/components/breakpoint';

import styles from './index.styl';


const infoItem = <div className={cx(styles.infoItem, 'lb-skeleton')} />;

const info = (
  <div className={styles.info}>
    {new Array(2).fill(infoItem)}
  </div>
);

const first = (
  <div>
    <Mobile>
      {info}
    </Mobile>
    <div className={cx(styles.text, 'lb-skeleton')} />
    <Desktop>
      {info}
    </Desktop>
  </div>
);

const second = (
  <div className={styles.secondItem}>
    <div className={cx(styles.image, 'lb-skeleton')} />
    <div className={styles.secondContent}>
      <div className={cx(styles.text, 'lb-skeleton')} />
    </div>
  </div>
);

export default function Skeleton() {
  return (
    <Fragment>
      <div className={styles.first}>
        {new Array(6).fill(first)}
      </div>
      <div className={styles.second}>
        {new Array(2).fill(second)}
      </div>
    </Fragment>
  );
}
